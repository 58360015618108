import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { WWCharacter } from '../../../modules/ww/common/components/ww-character';
import { WWWeaponEntry } from '../../../modules/ww/weapons/ww-weapon';

const WWGuidesGacha: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Gacha system</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_gacha.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Gacha system</h1>
          <h2>Information about the gacha system in Wuthering Waves!</h2>
          <p>
            Last updated: <strong>04/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Gacha System" />
        <p>
          Wuthering Waves uses a gacha system as the primary way for a player to
          obtain new Resonators and Weapons. It is generally the only way to
          obtain the highest rarity Resonators and Weapons.
        </p>
        <SectionHeader title="Convenes" />
        <p>
          <strong>Convene</strong> is what Wuthering Waves calls its gacha
          banners. To roll on the gacha or convening, you will need to use
          either <strong>Astrites</strong>, the gacha currency, or{' '}
          <strong>Tides</strong> which are the gacha tickets.
        </p>
        <p>
          <strong>Astrites and Tides</strong> can be obtained from various
          in-game sources as missions, events and certain shops.
        </p>
        <ul>
          <li>
            You can exchange <strong>160 Astrite</strong> for either 1 Lustrous
            Tide, 1 Radiant Tide or 1 Forging Tide.
          </li>
          <li>
            <strong>Lustrous Tides</strong> are used on the Novice Convene,
            Character Permanent Convene and Weapon Permanent Convene.{' '}
          </li>
          <li>
            <strong>Radiant Tides</strong> are used on the Character Event
            Convene.
          </li>
          <li>
            <strong>Forging Tides</strong> are used on the Weapon Event Convene.
          </li>
        </ul>
        <p>You can obtain the following from Convening:</p>
        <ul>
          <li>
            <strong className="ww-color-3">3★</strong> Weapons
          </li>
          <li>
            <strong className="ww-color-4">4★</strong> Weapons
          </li>
          <li>
            <strong className="ww-color-5">5★</strong> Weapons
          </li>
          <li>
            <strong className="ww-color-4">4★</strong> Resonators
          </li>
          <li>
            <strong className="ww-color-5">5★</strong> Resonators
          </li>
        </ul>
        <p>
          Stars denote the rarity of the Resonator or Weapon. The higher number
          of stars, the higher the rarity (and the more difficult) it is to
          obtain.
        </p>
        <p>
          <strong className="ww-color-4">4★</strong> <strong>Guarantee</strong>:
          If a player does not obtain a{' '}
          <strong className="ww-color-4">4★</strong> Resonator or Weapon after 9
          Convenes, the 10th Convene is guaranteed to be a{' '}
          <strong className="ww-color-4">4★</strong> Resonator or Weapon. This
          counter resets every time a player obtains a{' '}
          <strong className="ww-color-4">4★</strong> Resonator or Weapon.
        </p>
        <SectionHeader title="Oscillate Corals and Afterglow Corals" />
        <p>
          <strong>Oscillate Corals</strong> and{' '}
          <strong>Afterglow Corals</strong> are in-game currencies that are only
          obtainable by convening. They can be used in their respective exchange
          stores to purchase Tides, Wavebands, upgrade materials, and other
          resources.
        </p>
        <p>
          You can get Oscillate Corals by obtaining a{' '}
          <strong className="ww-color-3">3★</strong> Weapon from convening. Each{' '}
          <strong className="ww-color-3">3★</strong> Weapon will give you 15
          Oscillate Corals.{' '}
        </p>
        <p>You can get Afterglow Corals by:</p>
        <ul>
          <li>
            Obtaining any <strong className="ww-color-4">4★</strong> Weapon:
          </li>
          <ul>
            <li>
              <strong>3</strong> Afterglow Corals
            </li>
          </ul>
          <li>
            Obtaining any <strong className="ww-color-5">5★</strong> Weapon:
          </li>
          <ul>
            <li>
              <strong>15</strong> Afterglow Corals{' '}
            </li>
          </ul>
          <li>
            Obtaining a duplicate <strong className="ww-color-4">4★</strong>{' '}
            Resonator:
          </li>
          <ul>
            <li>
              1st to 6th duplicate: 3 Afterglow Corals and 1 Waveband of that
              Resonator
            </li>
            <li>7th duplicate onwards: 8 Afterglow Corals</li>
            <li>
              Note that you will not receive any Afterglow Corals when you pull
              a <strong className="ww-color-4">4★</strong> Resonator that you do
              not already own for the first time.
            </li>
          </ul>
          <li>
            Obtaining a duplicate <strong className="ww-color-5">5★</strong>{' '}
            Resonator:
          </li>
          <ul>
            <li>
              1st to 6th duplicate: 15 Afterglow Corals and 1 Waveband of that
              Resonator
            </li>
            <li>7th duplicate onwards: 40 Afterglow Corals</li>
            <li>
              Note that you will not receive any Afterglow Corals when you pull
              a <strong className="ww-color-5">5★</strong> Resonator that you do
              not already own for the first time.
            </li>
          </ul>
        </ul>
        <h5>Wavebands</h5>
        <p>
          Using the Afterglow Coral you can purchase up to 2 Wavebands (dupes)
          for any of the standard <strong className="ww-color-5">5★</strong>{' '}
          Resonators:
        </p>
        <StaticImage
          src="../../../images/ww/generic/reroll_7.webp"
          alt="Guides"
        />
        <p>
          This includes the current Event character, but they cost more
          Afterglow Coral to buy.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="Novice Convene" />
        <StaticImage
          src="../../../images/ww/generic/reroll_1.webp"
          alt="Guides"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            You can only Convene <strong>50 times</strong> on the Novice
            Convene, but you are guaranteed to obtain one{' '}
            <strong className="ww-color-5">5★</strong> Resonator within the 50
            Convenes.
          </li>
          <li>
            You cannot do single Convenes on the Novice Convene. You can only
            Convene in multiples of 10 (commonly referred to as a ten pull or a
            multi pull). However, you will receive a{' '}
            <strong>20% discount</strong> every time you Convene, so it will
            only cost 8 Lustrous Tides instead of 10.
          </li>
          <li>
            The rate for pulling a <strong className="ww-color-5">5★</strong>{' '}
            Resonator from this banner is currently set at <strong>0.8%</strong>
            .
          </li>
          <li>
            The rate for pulling a <strong className="ww-color-4">4★</strong>{' '}
            Resonator or Weapon from this banner is currently set at{' '}
            <strong>6.0%</strong>.
          </li>
          <li>
            No Resonators or Weapons have an increased rate on this Convene.
          </li>
          <li>Once you pull a 5★ character, the banner will vanish.</li>
          <li>
            You can only use <strong>Lustrous Tides</strong> on this banner
            (basic summon ticket).
          </li>
        </ul>
        <p>
          You can only obtain one of the below{' '}
          <strong className="ww-color-5">5★</strong> Resonators from the Novice
          Convene:
        </p>
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="verina" enablePopover showLabel />
          <WWCharacter mode="icon" slug="encore" enablePopover showLabel />
          <WWCharacter mode="icon" slug="calcharo" enablePopover showLabel />
          <WWCharacter mode="icon" slug="lingyang" enablePopover showLabel />
          <WWCharacter mode="icon" slug="jianxin" enablePopover showLabel />
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="Character Permanent Convene" />
        <StaticImage
          src="../../../images/ww/generic/reroll_2.webp"
          alt="Guides"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            You are guaranteed to obtain one{' '}
            <strong className="ww-color-5">5★</strong> Resonator within 80
            Convenes and the pity isn't shared with Event Convenes.
          </li>
          <li>
            The rate for pulling a <strong className="ww-color-5">5★</strong>{' '}
            Resonator from this banner is currently set at <strong>0.8%</strong>
            .
          </li>
          <li>
            The rate for pulling a <strong className="ww-color-4">4★</strong>{' '}
            Resonator or Weapon from this banner is currently set at{' '}
            <strong>6%</strong>.
          </li>
          <li>
            No Resonators or Weapons have an increased rate on this Convene.
          </li>
          <li>
            You can only use <strong>Lustrous Tides</strong> on this banner
            (basic summon ticket).
          </li>
        </ul>
        <p>
          You can only obtain one of the below{' '}
          <strong className="ww-color-5">5★</strong> Resonators from the Novice
          Convene:
        </p>
        <div className="employee-container for-nikke">
          <WWCharacter mode="icon" slug="verina" enablePopover showLabel />
          <WWCharacter mode="icon" slug="encore" enablePopover showLabel />
          <WWCharacter mode="icon" slug="calcharo" enablePopover showLabel />
          <WWCharacter mode="icon" slug="lingyang" enablePopover showLabel />
          <WWCharacter mode="icon" slug="jianxin" enablePopover showLabel />
        </div>
        <SectionHeader title="Weapon Permanent Convene" />
        <StaticImage
          src="../../../images/ww/generic/reroll_3.webp"
          alt="Guides"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            You can select one of <strong className="ww-color-5">5★</strong>{' '}
            Weapons on this banner as the one you want to obtain - one for each
            archetype.
          </li>
          <li>
            You are guaranteed to obtain the selected{' '}
            <strong className="ww-color-5">5★</strong> Weapon within 80 Convenes
            and the pity isn't shared with Weapon Event Convenes.
          </li>
          <li>
            The rate for pulling a <strong className="ww-color-5">5★</strong>{' '}
            Weapon from this banner is currently set at <strong>0.8%</strong>.
          </li>
          <li>
            The rate for pulling a <strong className="ww-color-4">4★</strong>{' '}
            Resonator or Weapon from this banner is currently set at{' '}
            <strong>6%</strong>.
          </li>
          <li>
            You can only use <strong>Lustrous Tides</strong> on this banner
            (basic summon ticket).
          </li>
        </ul>
        <StaticImage
          src="../../../images/ww/generic/reroll_6.webp"
          alt="Guides"
        />
        <p>
          You can only obtain one of the below{' '}
          <strong className="ww-color-5">5★</strong> Weapons from the Novice
          Convene:
        </p>
        <div className="ww-weapons-container">
          <div>
            <WWWeaponEntry name="Emerald of Genesis" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Abyss Surges" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Static Mist" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Cosmic Ripples" level={[1]} mode="icon" />
          </div>
          <div>
            <WWWeaponEntry name="Lustrous Razor" level={[1]} mode="icon" />
          </div>
        </div>
        <SectionHeader title="Character Event Convene" />
        <StaticImage
          src="../../../images/ww/generic/reroll_4.webp"
          alt="Guides"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            One <strong className="ww-color-5">5★</strong> Resonator and three{' '}
            <strong className="ww-color-4">4★</strong> Resonators will be
            featured on any given Character Event Convene.
          </li>
          <li>
            The rate for pulling a <strong className="ww-color-5">5★</strong>{' '}
            Resonator from this banner is currently set at <strong>0.8%</strong>
            .
          </li>
          <li>
            The rate for pulling a <strong className="ww-color-4">4★</strong>{' '}
            Resonator or Weapon from this banner is currently set at{' '}
            <strong>6%</strong>.
          </li>
          <li>
            Once you pull a <strong className="ww-color-5">5★</strong>{' '}
            Resonator, you have <strong>50%</strong> chance of it being the
            Rate-up one.
          </li>
          <li>
            If you lose the 50/50 then the second{' '}
            <strong className="ww-color-5">5★</strong> Resonator you pull will
            100% be the Rate-up one.
          </li>
          <li>
            You are guaranteed to obtain a{' '}
            <strong className="ww-color-5">5★</strong> within 80 pulls (that's
            the pity). So if you're unlucky, it will take you at most 160 pulls
            to get the rate-up Resonator.
          </li>
          <li>
            You can only use <strong>Radiant Tides</strong> on this banner.
          </li>
        </ul>
        <SectionHeader title="Weapon Event Convene" />
        <StaticImage
          src="../../../images/ww/generic/reroll_5.webp"
          alt="Guides"
        />
        <h5>Information about this banner</h5>
        <ul>
          <li>
            One <strong className="ww-color-5">5★</strong> Weapon and three{' '}
            <strong className="ww-color-4">4★</strong> Weapons will be featured
            on any given Weapon Event Convene.
          </li>
          <li>
            The rate for pulling a <strong className="ww-color-5">5★</strong>{' '}
            Weapon from this banner is currently set at <strong>0.8%</strong>.
          </li>
          <li>
            The rate for pulling a <strong className="ww-color-4">4★</strong>{' '}
            Resonator or Weapon from this banner is currently set at{' '}
            <strong>6%</strong>.
          </li>
          <li>
            Once you pull a <strong className="ww-color-5">5★</strong> Weapon,
            It is <strong>100% guaranteed</strong> to be the featured Weapon.
          </li>
          <li>
            You are guaranteed to obtain a{' '}
            <strong className="ww-color-5">5★</strong> within 80 pulls (that's
            the pity).
          </li>
          <li>
            You can only use <strong>Forging Tides</strong> on this banner.
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesGacha;

export const Head: React.FC = () => (
  <Seo
    title="Gacha system | Wuthering Waves | Prydwen Institute"
    description="Information about the gacha system in Wuthering Waves!"
    game="ww"
  />
);
